import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import Button, { ButtonGroup, ButtonGroupAlign } from '../Base/Button'
import * as styles from './index.module.less'

import homeBg from './img/homeBg.png'
import homeBgMb from './img/homeBgMb.png'
import healthBg from './img/healthCareBg.png'
import healthCareBgMb from './img/healthCareBgMb.png'
import newBg from './img/newBg.png'
import newBgMb from './img/newBgMb.png'

const ImgMap = {
  index: [homeBg, homeBgMb],
  healthCare: [healthBg, healthCareBgMb],
  newBg: [newBg, newBgMb],
}
interface PageBottomCardProps {
  className?: string
  title?: string
  desc?: string
  leftButtonText?: string
  leftButtonHref?: string
  rightButtonText?: string
  rightButtonHref?: string
  onClickLeftButton?: React.MouseEventHandler<HTMLElement>
  children?: any
  /** 背景图类型 */
  type?: 'index' | 'healthCare' | 'newBg'
  descClassName?: string
}

interface ExtendedCSSProperties extends CSSProperties {
  /** 背景图片 */
  '--bgImage': string
  /** 移动端背景图片 */
  '--bgImageMb': string
}

const PageBottomCardNew: React.FC<PageBottomCardProps> = ({
  className,
  title,
  desc,
  leftButtonText,
  leftButtonHref,
  rightButtonText,
  rightButtonHref,
  onClickLeftButton,
  children,
  descClassName,
  type = 'index',
}) => {
  const style = {
    PageBottomCard: `relative h-[auto] pt-[3.2rem] pb-[2.3rem] lg:pt-[4rem] lg:pb-[3rem] !px-0 lg:py-[60px] px-0`,
    PageBottomCardContent: `px-[2rem] lg:p-0 max-w-[1200px] my-0 mx-auto text-center`,
    PageBottomCardTitle: `text-[2rem] lg:text-[28px] text-sd-black font-normal leading-[40px]`,
    PageBottomCardSubTitle: `text-[1.2rem] lg:text-[16px] text-sd-black font-normal leading-[2] lg:leading-[3rem] lg:leading-none ml-[1rem] mr-0 lg:mt-[10px]`,
  }

  // 获取背景图片
  const [bg, bgMb] = ImgMap[type] || ['', '']

  const inlineStyle: ExtendedCSSProperties = {
    backgroundSize: '100% 100%',
    '--bgImage': `url(${bg})`,
    '--bgImageMb': `url(${bgMb})`,
  }

  return (
    <section
      className={classnames(styles.pageBottom, style.PageBottomCard, 'page-bottom-card', className)}
      style={inlineStyle}
    >
      <article className={style['PageBottomCardContent']}>
        <h2 className={style['PageBottomCardTitle']}>{title}</h2>
        <p className={classnames(style['PageBottomCardSubTitle'], descClassName)}>{desc}</p>
        <ButtonGroup align={ButtonGroupAlign.Center} className={classnames('mt-[2rem] mb-[1rem] lg:mt-[30px]')}>
          {leftButtonText && (
            <Button
              className="px-[1rem] py-[0.8rem] text-[14px] lg:px-[2rem]"
              href={leftButtonHref}
              target="_blank"
              btnType="primary"
              onClick={onClickLeftButton}
              style={{ display: !leftButtonText ? 'none' : undefined }}
            >
              {leftButtonText}
            </Button>
          )}
          {rightButtonText && (
            <Button
              className="px-[1rem] py-[0.8rem] text-[14px] lg:px-[2rem]"
              btnType="primary"
              ghost
              target="_blank"
              href={rightButtonHref}
              style={{ display: !rightButtonText ? 'none' : undefined }}
            >
              {rightButtonText}
            </Button>
          )}
        </ButtonGroup>

        {children}
      </article>
    </section>
  )
}

export default PageBottomCardNew
